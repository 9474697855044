




import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask.vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';

export default defineComponent({
  name: 'RecrystallizationSIM',
  components: {LabSim, LabTask},
  mixins: [DynamicQuestionMixin()],
});
